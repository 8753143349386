import React from "react";
import { useParams } from "react-router-dom";
import "./Projects.css";
import ProjectCard from "../cards/ProjectCard";
import Header from "../navigation/Header";
import { projectsData } from "../../assets/comondata/ProjectsData";

const Projects = () => {
  const { location } = useParams();
  const projects = projectsData[location] || [];

  return (
    <div className="div-body">
      <Header />
      <div className="projects-page">
        <h1 className="project-title">Projects in {location}</h1>
        {projects.length > 0 ? (
          <section id="projects" className="project-list">
            <div className="projects">
              {projects.map((project) => (
                <ProjectCard
                  key={project.id}
                  project={project}
                  location={location}
                />
              ))}
            </div>
          </section>
        ) : (
          <p>No projects found for this location.</p>
        )}
      </div>
    </div>
  );
};

export default Projects;
