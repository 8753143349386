import React from "react";
import { useParams } from "react-router-dom";
import { apartmentsData } from "../../assets/comondata/ApartmentsData";
import Carousel from "../cards/Carousel";
import Header from "../navigation/Header";
import "./ApartmentDetails.css";
import ApartmentDetailsList from "../lists/ApartmentDetailsList";
import { Button } from "@mui/material";

const ApartmentDetails = () => {
  const { projectId, apartmentId } = useParams();
  const apartments = apartmentsData[projectId] || [];
  let found = 0;
  const win = window.location.href;

  const handleLearnMore = () => {
    const message = `Can i get more info about this apartment? ${win}`;
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/+96176576768?text=${encodedMessage}`);
  };

  return (
    <div className="apartment-details-div-body">
      <Header className="header" />
      {apartments.map((apartment, index, apartments) => {
        if (apartment.id === parseInt(apartmentId)) {
          found = 1;
          return (
            <div key={apartment.id}>
              <div className="carousel-container">
                <Carousel images={apartment.carouselImages} />
              </div>
              <div className="apartment-details-div">
                <div className="apartment-details">
                    <h2>{apartment.name}</h2>
                    <p className={`status ${apartment.available ? '' : 'not-available'}`}>
                        Status: {apartment.available ? "Available" : "Not Available"}
                    </p>
                    <ApartmentDetailsList key={apartment.id} dataName1="Floor" data1={apartment.floor} dataName2="Position" data2={apartment.position} />
                    <ApartmentDetailsList key={apartment.id+1} dataName1="Total Area" data1={apartment.area} dataName2="Number of bedrooms" data2={apartment.bedrooms} />
                    <ApartmentDetailsList key={apartment.id+2} dataName1="Number of bathrooms" data1={apartment.bathrooms} dataName2="Number of parkings" data2={apartment.parkingspots} />
                    <ApartmentDetailsList key={apartment.id+3} dataName1="Terrace available" data1={apartment.terraceavailable} dataName2="Maid Room" data2={apartment.maidroom} />
                    <ApartmentDetailsList key={apartment.id+3} dataName1="Water tanks" data1={apartment.watertank} dataName2="Solar water heater" data2={apartment.solarsystem} />
                    <div className="btn-div">
                      <Button
                        variant="contained"
                        className="learnMoreButton"
                        onClick={handleLearnMore}
                      >
                        Contact us for info
                      </Button>
                    </div>
                </div>
              </div>
            </div>
          );
        }
        if (apartments.length - 1 === index && found === 0) {
          return <div key="no-apartments" className="no-apartments-found">No Apartments Found</div>;
        }
        return null;
      })}
    </div>
  );
};

export default ApartmentDetails;
