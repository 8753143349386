import React from "react";
import { useParams } from "react-router-dom";
import { /*Card, CardContent, CardMedia, Typography, */Box } from "@mui/material";
import "./Apartments.css";
import Header from "../navigation/Header";
import AppartmentsCard from "../cards/ApartmentsCard";
import { apartmentsData } from "../../assets/comondata/ApartmentsData";
import { projectsData } from "../../assets/comondata/ProjectsData";
// apartmentsData.js
/*const apartmentsData = {
  1: [
    {
      id: 1,
      name: "Apartment 1",
      floor: 1,
      available: true,
      price: "$400,000",
      image: "https://th.bing.com/th/id/OIP.a8aJNzM9c_QqKl0LMojNHQAAAA?rs=1&pid=ImgDetMain",
    },
    {
      id: 2,
      name: "Apartment 2",
      floor: 2,
      available: false,
      price: "$450,000",
      image: "https://th.bing.com/th/id/OIP.iUlRe4gSxALY6b_0nPUxigHaE8?pid=ImgDet&w=474&h=316&rs=1",
    },
  ],
  2: [
    {
      id: 3,
      name: "Apartment 3",
      floor: 1,
      available: true,
      price: "$500,000",
      image: "https://example.com/apartment3.jpg",
    },
    {
      id: 4,
      name: "Apartment 4",
      floor: 2,
      available: false,
      price: "$550,000",
      image: "https://example.com/apartment4.jpg",
    },
  ],
  // Add more projects and apartments as needed
};*/

const Apartments = () => {
  const { projectId, location } = useParams();
  const apartments = apartmentsData[projectId] || [];
  const project = projectsData[location][0] ||[];

  return (
    <div className="div-body">
      <Header />
      <div className="apartments-page">
        <h1 className="apartments-title">
          Apartments in {project.name} at {location}
        </h1>
        {apartments.length > 0 ? (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4, justifyContent: "center" }}>
            {apartments.map((apartment) => (
              /*<Box key={apartment.id} sx={{ flexBasis: { xs: '100%', sm: '48%', md: '30%' } }}>
                <Card className="apartment-card">
                  <CardMedia
                    component="img"
                    alt={apartment.name}
                    height="200"
                    image={apartment.image}
                  />
                  <CardContent>
                    <Typography variant="h5">{apartment.name}</Typography>
                    <Typography variant="body2">Floor: {apartment.floor}</Typography>
                    <Typography variant="body2">Price: {apartment.price}</Typography>
                    <Typography variant="body2">Availability: {apartment.available ? "Available" : "Sold Out"}</Typography>
                  </CardContent>
                </Card>
              </Box>*/
              <AppartmentsCard 
              key={apartment.id}
              apartment={apartment}/>
            ))}
          </Box>
        ) : (
          <p>No apartments found for this project.</p>
        )}
      </div>
    </div>
  );
};

export default Apartments;
