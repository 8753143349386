import React from "react";
import { Card, CardContent, CardMedia, Typography, Box, Button } from "@mui/material";
import styles from "./ApartmentsCard.module.css";
import { useNavigate, useParams } from 'react-router-dom';

const AppartmentsCard = ({ apartment }) => {
  const navigate = useNavigate();
  const { location,projectId } = useParams();

  const handleLearnMore = () => {
    navigate(`/location/${location}/${projectId}/${apartment.id}`);
  };

  return (
    <Box
      key={apartment.id}
      sx={{ flexBasis: { xs: "100%", sm: "48%", md: "30%" } }}
    >
      <Card className={styles["apartment-card"]}>
        <CardMedia
          component="img"
          alt={apartment.name}
          height="200"
          image={apartment.image}
        />
        <CardContent className={styles.cardContent}>
          <Typography variant="h5" lassName={styles.cardTiltle}>{apartment.name}</Typography>
          <Typography variant="body2">Floor: {apartment.floor}</Typography>
          <Typography variant="body2">Price: {apartment.price}</Typography>
          <Typography variant="body2">
            Availability: {apartment.available ? "Available" : "Not Available"}
          </Typography>
          <Box className={styles.footer}>
            <Button
              variant="contained"
              className={styles.learnMoreButton}
              onClick={handleLearnMore}
            >
              See More
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AppartmentsCard;
