export const apartmentsData = {
  Lazareth4678: [
      {
        id: 1,
        name: "First floor west",
        floor: 1,
        position: "West",
        area: "213 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes",
        terracearea: 1,
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/izwFhob.png",//"https://th.bing.com/th/id/OIP.a8aJNzM9c_QqKl0LMojNHQAAAA?rs=1&pid=ImgDetMain",
        carouselImages: [
          'https://i.imgur.com/izwFhob.png'
        ]
      },
      {
        id: 2,
        name: "First floor east",
        floor: 1,
        position: "east",
        area: "217 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes",
        terracearea: 1,
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/s902zte.png",//"https://th.bing.com/th/id/OIP.a8aJNzM9c_QqKl0LMojNHQAAAA?rs=1&pid=ImgDetMain",
        carouselImages: [
          'https://i.imgur.com/s902zte.png'
        ]
      },
      {
        id: 3,
        name: "First floor east",
        floor: 1,
        position: "east",
        area: "217 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes",
        terracearea: 1,
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/s902zte.png",//"https://th.bing.com/th/id/OIP.a8aJNzM9c_QqKl0LMojNHQAAAA?rs=1&pid=ImgDetMain",
        carouselImages: [
          'https://i.imgur.com/s902zte.png'
        ]
      },
      {
        id: 4,
        name: "First floor east",
        floor: 1,
        position: "east",
        area: "217 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes",
        terracearea: 1,
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/s902zte.png",//"https://th.bing.com/th/id/OIP.a8aJNzM9c_QqKl0LMojNHQAAAA?rs=1&pid=ImgDetMain",
        carouselImages: [
          'https://i.imgur.com/s902zte.png'
        ]
      },
      {
        id: 5,
        name: "First floor east",
        floor: 1,
        position: "east",
        area: "217 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes",
        terracearea: 1,
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/s902zte.png",//"https://th.bing.com/th/id/OIP.a8aJNzM9c_QqKl0LMojNHQAAAA?rs=1&pid=ImgDetMain",
        carouselImages: [
          'https://i.imgur.com/s902zte.png'
        ]
      },
      {
        id: 6,
        name: "First floor east",
        floor: 1,
        position: "east",
        area: "217 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes",
        terracearea: 1,
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/s902zte.png",//"https://th.bing.com/th/id/OIP.a8aJNzM9c_QqKl0LMojNHQAAAA?rs=1&pid=ImgDetMain",
        carouselImages: [
          'https://i.imgur.com/s902zte.png'
        ]
      },
      {
        id: 7,
        name: "First floor east",
        floor: 1,
        position: "east",
        area: "217 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes",
        terracearea: 1,
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/s902zte.png",//"https://th.bing.com/th/id/OIP.a8aJNzM9c_QqKl0LMojNHQAAAA?rs=1&pid=ImgDetMain",
        carouselImages: [
          'https://i.imgur.com/s902zte.png'
        ]
      },
      {
        id: 8,
        name: "First floor east",
        floor: 1,
        position: "east",
        area: "217 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes",
        terracearea: 1,
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/s902zte.png",//"https://th.bing.com/th/id/OIP.a8aJNzM9c_QqKl0LMojNHQAAAA?rs=1&pid=ImgDetMain",
        carouselImages: [
          'https://i.imgur.com/s902zte.png'
        ]
      },
    ]
  };